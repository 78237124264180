const sourceFunds = [{
  key: 'pages_source_funds_001',
  value: '0'
},
{
  key: 'pages_source_funds_002',
  value: '1'
},
{
  key: 'pages_source_funds_003',
  value: '2'
},
{
  key: 'pages_source_funds_004',
  value: '3'
},
{
  key: 'pages_source_funds_005',
  value: '4'
},
{
  key: 'pages_source_funds_006',
  value: '5'
},
{
  key: 'pages_source_funds_007',
  value: '6'
},
{
  key: 'pages_source_funds_008',
  value: '7'
},
{
  key: 'pages_source_funds_009',
  value: '8'
},
{
  key: 'pages_source_funds_010',
  value: '9'
},
{
  key: 'pages_source_funds_011',
  value: '10'
},
{
  key: 'pages_source_funds_012',
  value: '11'
},
{
  key: 'pages_source_funds_013',
  value: '12'
}]

export default sourceFunds
