const sourceWheres = [
  {
    key: 'pages_source_whereabouts_001',
    value: '0'
  },
  {
    key: 'pages_source_whereabouts_002',
    value: '1'
  },
  {
    key: 'pages_source_whereabouts_003',
    value: '2'
  },
  {
    key: 'pages_source_whereabouts_004',
    value: '3'
  },
  {
    key: 'pages_source_whereabouts_005',
    value: '4'
  },
  {
    key: 'pages_source_whereabouts_006',
    value: '5'
  },
  {
    key: 'pages_source_whereabouts_007',
    value: '6'
  },
  {
    key: 'pages_source_whereabouts_008',
    value: '7'
  },
  {
    key: 'pages_source_whereabouts_009',
    value: '8'
  },
  {
    key: 'pages_source_whereabouts_010',
    value: '9'
  },
  {
    key: 'pages_source_whereabouts_011',
    value: '10'
  },
  {
    key: 'pages_source_whereabouts_012',
    value: '11'
  },
  {
    key: 'pages_source_whereabouts_013',
    value: '12'
  },
  {
    key: 'pages_source_whereabouts_014',
    value: '13'
  },
  {
    key: 'pages_source_whereabouts_015',
    value: '14'
  },
  {
    key: 'pages_source_whereabouts_016',
    value: '15'
  },
  {
    key: 'pages_source_whereabouts_017',
    value: '16'
  },
  {
    key: 'pages_source_whereabouts_018',
    value: '17'
  },
  {
    key: 'pages_source_whereabouts_019',
    value: '18'
  },
  {
    key: 'pages_source_whereabouts_020',
    value: '19'
  },
  {
    key: 'pages_source_whereabouts_021',
    value: '20'
  },
  {
    key: 'pages_source_whereabouts_022',
    value: '21'
  },
  {
    key: 'pages_source_whereabouts_023',
    value: '22'
  },
  {
    key: 'pages_source_whereabouts_024',
    value: '23'
  },
  {
    key: 'pages_source_whereabouts_025',
    value: '24'
  },
  {
    key: 'pages_source_whereabouts_026',
    value: '25'
  },
  {
    key: 'pages_source_whereabouts_027',
    value: '26'
  },
  {
    key: 'pages_source_whereabouts_028',
    value: '27'
  },
  {
    key: 'pages_source_whereabouts_029',
    value: '28'
  }
]
export default sourceWheres
