<template>
  <div id="declarePage">
    <a-form
      ref="step12Ref"
      class="ant-round-form material-declare-page-form"
      layout="vertical"
      :model="modelForm"
      :rules="rules"
      @finish="submit"
    >
      <a-form-item name="declareRadio">
        <template #label>
          <div>
            {{ $t('pages_merchant_085') }}
            <span style="color: red">{{ $t('pages_merchant_086') }}</span>
            {{ $t('pages_merchant_087') }}
          </div>
        </template>
        <a-radio-group
          v-model:value="modelForm.declareRadio"
          style="width: 100%"
          :disabled="modelForm.declareRadioDisabled"
        >
          <a-radio value="1" :class="modelForm.declareRadio === '1' ? 'radio-area selected' : 'radio-area'">
            {{ $t('pages_merchant_022') }}
          </a-radio>
          <a-radio value="0" :class="modelForm.declareRadio === '0' ? 'radio-area selected' : 'radio-area'">
            {{ $t('pages_merchant_023') }}
          </a-radio>
        </a-radio-group>
        <div v-if="!modelForm.declareRadioDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.declareRadioMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item v-if="modelForm.declareRadio === '1'" name="tradeCountry" :label="$t('pages_merchant_088')">
        <span class="explain">{{ $t('pages_merchant_089') }}</span>
        <div
          v-for="(item, index) in modelForm.tradeCountry"
          :key="item.id"
          class="trade-country-area"
        >
          <a-form-item
            :name="['tradeCountry', index, 'country']"
            :rules="{ required: true, message: $t('common_text_005') }"
            style="margin-bottom: 12px"
          >
            <a-select
              v-model:value="item.country"
              show-search
              size="large"
              option-filter-prop="label"
              :placeholder="$t('common_text_005')"
              :disabled="modelForm.tradeCountryDisabled"
            >
              <a-select-option
                v-for="country in riskTransactions"
                :key="country.areaCode"
                :value="country.areaCode"
                :label="language === 'zh' ? country.areaChineseName : country.areaEnglishName"
              >{{ language === 'zh' ? country.areaChineseName : country.areaEnglishName }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :name="['tradeCountry', index, 'name']"
            :rules="{ required: true, message: $t('pages_merchant_090') }"
          >
            <a-input
              v-model:value="item.name"
              size="large"
              allow-clear
              :placeholder="$t('pages_merchant_090')"
              :disabled="modelForm.tradeCountryDisabled"
            />
          </a-form-item>
          <div v-if="!modelForm.tradeCountryDisabled" class="edit-area">
            <plus-circle-filled v-if="index === 0" class="ic-add" @click="handleEdit(0)" />
            <minus-circle-filled v-else class="ic-delete" @click="handleEdit(index)" />
          </div>
        </div>
        <div v-if="!modelForm.tradeCountryDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.tradeCountryMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item name="checkOne">
        <a-checkbox v-model:checked="modelForm.checkOne">{{ $t('pages_merchant_091') }}</a-checkbox>
      </a-form-item>

      <a-form-item name="checkTwo">
        <a-checkbox v-model:checked="modelForm.checkTwo">{{ $t('pages_merchant_092') }}{{ themeData.saasMerNameZh }}</a-checkbox>
      </a-form-item>

      <a-form-item style="text-align: right; margin-top: 48px">
        <a-button
          type="primary"
          shape="round"
          ghost
          style="min-width: 138px; margin-right: 24px"
          @click="$emit('next', risked ? '3-4' : '3-3')"
        >{{ $t('pages_merchant_040') }}</a-button>
        <a-button
          html-type="submit"
          type="primary"
          shape="round"
          style="min-width: 138px"
          :disabled="!modelForm.checkOne || !modelForm.checkTwo"
          :loading="loading"
        >{{ $t('common_text_002') }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, computed } from 'vue'
export default {
  name: 'DeclarePage',
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    },
    risked: {
      type: Boolean,
      default: () => { return false }
    },
    countryCode: {
      type: String,
      default: () => { return '' }
    },
    themeData: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup (props, ctx) {
    const step12Ref = ref(null)

    const state = reactive({
      loading: false,
      language: localStorage.getItem('locale-language') || 'zh',
      rules: {
        declareRadio: [{ required: true }],
        tradeCountry: [{ required: true }]
      },
      riskTransactions: [
        {
          id: 1,
          areaCode: 'IR',
          areaEnglishName: 'IRAN',
          areaChineseName: '伊朗'
        },
        {
          id: 2,
          areaCode: 'KP',
          areaEnglishName: 'NORTH KOREA',
          areaChineseName: '朝鲜'
        },
        {
          id: 3,
          areaCode: 'CU',
          areaEnglishName: 'CUBA',
          areaChineseName: '古巴'
        }
      ]
    })

    state.modelForm = computed(() => {
      return props.model
    })

    const handleEdit = (currentIndex) => {
      if (currentIndex) state.modelForm.tradeCountry = state.modelForm.tradeCountry.filter((item, index) => index !== currentIndex)
      else state.modelForm.tradeCountry.push({ country: null, name: '' })
    }

    const submit = () => {
      ctx.emit('finish')
    }

    return {
      step12Ref,
      submit,
      handleEdit,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#declarePage {
  .material-declare-page-form {
    .explain {
      color: #495060;
    }
    .radio-area {
      display: flex;
      margin: 12px 0 24px 0;
      border: 1px solid #d9d9d9;
      padding: 12px 24px;
      border-radius: 5px;
      cursor: pointer;
    }
    .radio-area:hover, .selected {
      border: 1px solid @main-color;
    }
    .trade-country-area {
      margin-top: 24px;
      .edit-area {
        text-align: right;
        padding-right: 12px;
        margin-top: -6px;
        .ic-delete, .ic-add  {
          color: @main-color;
          cursor: pointer;
          font-size: 20px;
        }
      }
    }
  }
}

</style>

