<template>
  <div id="otherInfo">
    <a-form
      ref="step9Ref"
      class="ant-round-form material-other-info-form"
      layout="vertical"
      :model="modelForm"
      :rules="rules"
      @finish="submit"
    >

      <a-form-item name="docPath" :label="$t('pages_merchant_129')">
        <div class="explain" style="margin-bottom: 24px">
          <span>{{ $t('pages_merchant_130') }}</span>
        </div>
        <upload-auto
          ref="uploadRef"
          v-model:file-content="modelForm.docPath"
          accept=".jpg,.jpeg,.png,.pdf,.bmp,.xls,.xlsx,.csv,.doc,.docx,.rar,.zip"
          type="INVITE"
          list-type="picture"
          back-type="array"
          :disabled="modelForm.docDisabled"
          :explain="$t('pages_merchant_032')"
          :max-count="10"
        />
        <div v-if="!modelForm.docDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.docMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item name="supplyText" :label="$t('pages_merchant_131')">
        <a-textarea v-model:value="modelForm.supplyText" class="input-primary" :placeholder="$t('pages_merchant_132')" allow-clear />
        <div v-if="!modelForm.supplyDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.supplyMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item style="text-align: right; margin-top: 48px">
        <a-button
          type="primary"
          shape="round"
          ghost
          style="min-width: 138px; margin-right: 24px"
          @click="$emit('next', '3-2')"
        >{{ $t('pages_merchant_040') }}</a-button>
        <a-button
          html-type="submit"
          type="primary"
          shape="round"
          style="min-width: 138px"
        >{{ $t('pages_merchant_020') }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, computed, nextTick } from 'vue'
import UploadAuto from '@/components/upload-auto'
export default {
  name: 'OtherInfo',
  components: {
    'upload-auto': UploadAuto
  },
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    },
    risked: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props, ctx) {
    const step14Ref = ref(null)
    const uploadRef = ref(null)

    const state = reactive({})

    state.modelForm = computed(() => {
      return props.model
    })

    const submit = () => {
      ctx.emit('next', props.risked ? '3-4' : '3-5')
    }

    nextTick(() => {
      const { docPath } = state.modelForm
      if (docPath.length !== 0 && uploadRef.value) uploadRef.value.fileListRefresh(docPath)
    })

    return {
      step14Ref,
      uploadRef,
      submit,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#otherInfo {
  .material-other-info-form {
    .explain {
      color: #495060;
    }
    .input-primary:deep(.ant-input) {
      border-radius: 5px;
    }
  }
}

</style>

