<template>
  <div id="businessLicense">
    <a-form
      ref="step4Ref"
      class="ant-round-form material-business-license-form"
      layout="vertical"
      :model="modelForm"
      :rules="rules"
      @finish="submit"
    >

      <a-form-item
        v-if="countryCode === 'CN'"
        name="businessLicensePath"
        :label="$t('pages_merchant_007')"
      >
        <div class="explain" style="margin-bottom: 24px">
          <span>{{ $t('pages_merchant_035') }}</span>
          <a :href="require('/src/assets/files/yingYeZhiZhao.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
          <span>{{ $t('pages_merchant_047') }}{{ $t('pages_merchant_007') }}{{ $t('pages_merchant_048') }}</span>
        </div>
        <upload-auto
          ref="uploadBRef"
          v-model:file-content="modelForm.businessLicensePath"
          accept=".jpg,.jpeg,.png,.pdf,.bmp"
          type="INVITE"
          list-type="picture"
          back-type="array"
          :disabled="modelForm.businessLicensePathDisabled"
          :explain="$t('pages_merchant_032')"
          :max-count="5"
        />
        <div v-if="!modelForm.businessLicensePathDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.businessLicensePathMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item
        v-else-if="countryCode === 'HK'"
        name="businessRegistrationCertificatePath"
        :label="$t('pages_merchant_016')"
      >
        <div class="explain" style="margin-bottom: 24px">
          <span>{{ $t('pages_merchant_035') }}</span>
          <a :href="require('/src/assets/files/shangYeDJZ.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
          <span>{{ $t('pages_merchant_047') }}{{ $t('pages_merchant_016') }}{{ $t('pages_merchant_048') }}</span>
        </div>
        <upload-auto
          ref="uploadCRef"
          v-model:file-content="modelForm.businessRegistrationCertificatePath"
          accept=".jpg,.jpeg,.png,.pdf,.bmp"
          type="INVITE"
          list-type="picture"
          back-type="array"
          :disabled="modelForm.certificatePathDisabled"
          :explain="$t('pages_merchant_032')"
          :max-count="5"
        />
        <div v-if="!modelForm.certificatePathDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.certificatePathMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item
        v-else
        name="incorporationPath"
        :label="$t('pages_merchant_017')"
      >
        <div class="explain" style="margin-bottom: 24px">
          <span>{{ $t('pages_merchant_035') }}</span>
          <a :href="require('/src/assets/files/zhuCeZhengMing.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
          <span>{{ $t('pages_merchant_047') }}{{ $t('pages_merchant_017') }}{{ $t('pages_merchant_048') }}</span>
        </div>
        <upload-auto
          ref="uploadIRef"
          v-model:file-content="modelForm.incorporationPath"
          accept=".jpg,.jpeg,.png,.pdf,.bmp"
          type="INVITE"
          list-type="picture"
          back-type="array"
          :disabled="modelForm.incorporationPathDisabled"
          :explain="$t('pages_merchant_032')"
          :max-count="5"
        />
        <div v-if="!modelForm.incorporationPathDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.incorporationPathMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item style="text-align: right; margin-top: 48px">
        <a-button
          type="primary"
          shape="round"
          ghost
          style="min-width: 138px; margin-right: 24px"
          @click="$emit('next', '1-3')"
        >{{ $t('pages_merchant_040') }}</a-button>
        <a-button
          html-type="submit"
          type="primary"
          shape="round"
          style="min-width: 138px"
        >{{ $t('pages_merchant_020') }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, computed, nextTick, onUnmounted } from 'vue'
import UploadAuto from '@/components/upload-auto'
import i18n from '@/locale'
export default {
  name: 'BusinessLicense',
  components: {
    'upload-auto': UploadAuto
  },
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    },
    countryCode: {
      type: String,
      default: () => { return '' }
    }
  },
  setup (props, ctx) {
    const step4Ref = ref(null)
    const uploadBRef = ref(null)
    const uploadCRef = ref(null)
    const uploadIRef = ref(null)

    const state = reactive({
      rules: {
        businessLicensePath: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_merchant_007') }],
        businessRegistrationCertificatePath: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_merchant_016') }],
        incorporationPath: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_merchant_017') }]
      }
    })

    state.modelForm = computed(() => {
      return props.model
    })

    onUnmounted(() => {
      if (props.countryCode === 'CN') {
        state.modelForm.businessRegistrationCertificatePath = []
        state.modelForm.incorporationPath = []
      } else if (props.countryCode === 'HK') {
        state.modelForm.businessLicensePath = []
        state.incorporationPath = []
      } else {
        state.modelForm.businessLicensePath = []
        state.modelForm.businessRegistrationCertificatePath = []
      }
    })

    const submit = () => {
      if (['CN', 'HK'].includes(props.countryCode)) ctx.emit('next', '2-4')
      else ctx.emit('next', '2-2')
    }

    nextTick(() => {
      const { businessLicensePath, businessRegistrationCertificatePath, incorporationPath } = state.modelForm
      if (props.countryCode === 'CN' && businessLicensePath.length !== 0 && uploadBRef.value) uploadBRef.value.fileListRefresh(businessLicensePath)
      if (props.countryCode === 'HK' && businessRegistrationCertificatePath.length !== 0 && uploadCRef.value) uploadCRef.value.fileListRefresh(businessRegistrationCertificatePath)
      if (!['HK', 'CN'].includes(props.countryCode) && incorporationPath.length !== 0 && uploadIRef.value) uploadIRef.value.fileListRefresh(incorporationPath)
    })

    return {
      step4Ref,
      uploadBRef,
      uploadCRef,
      uploadIRef,
      submit,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#businessLicense {
  .material-business-license {
    .explain {
      color: #495060;
    }
  }
}

</style>

