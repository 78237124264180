<template>
  <div id="articlesAssociation">
    <a-form
      ref="step5Ref"
      class="ant-round-form material-articles-association-form"
      layout="vertical"
      :model="modelForm"
      :rules="rules"
      @finish="submit"
    >

      <a-form-item name="mermorandumPath" :label="$t('pages_merchant_018')">
        <div class="explain" style="margin-bottom: 24px">
          <span>{{ $t('pages_merchant_049') }}</span>
        </div>
        <upload-auto
          ref="uploadRef"
          v-model:file-content="modelForm.mermorandumPath"
          accept=".jpg,.jpeg,.png,.pdf,.bmp"
          type="INVITE"
          list-type="picture"
          back-type="array"
          :disabled="modelForm.mermorandumPathDisabled"
          :explain="$t('pages_merchant_032')"
          :max-count="5"
        />
        <div v-if="!modelForm.mermorandumPathDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.mermorandumPathMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item style="text-align: right; margin-top: 48px">
        <a-button
          type="primary"
          shape="round"
          ghost
          style="min-width: 138px; margin-right: 24px"
          @click="$emit('next', '2-1')"
        >{{ $t('pages_merchant_040') }}</a-button>
        <a-button
          html-type="submit"
          type="primary"
          shape="round"
          style="min-width: 138px"
        >{{ $t('pages_merchant_020') }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, computed, nextTick } from 'vue'
import UploadAuto from '@/components/upload-auto'
import i18n from '@/locale'
export default {
  name: 'ArticlesAssociation',
  components: {
    'upload-auto': UploadAuto
  },
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props, ctx) {
    const step5Ref = ref(null)
    const uploadRef = ref(null)

    const state = reactive({
      rules: {
        mermorandumPath: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_merchant_018') }]
      }
    })

    state.modelForm = computed(() => {
      return props.model
    })

    const submit = () => {
      ctx.emit('next', '2-3')
    }

    nextTick(() => {
      if (uploadRef.value && state.modelForm.mermorandumPath.length !== 0) uploadRef.value.fileListRefresh(state.modelForm.mermorandumPath)
    })

    return {
      step5Ref,
      uploadRef,
      submit,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#articlesAssociation {
  .material-business-license {
    .explain {
      color: #495060;
    }
  }
}

</style>

