<template>
  <div id="sourceWhereabouts">
    <a-form
      ref="step11Ref"
      class="ant-round-form material-source-whereabouts-form"
      layout="vertical"
      :model="modelForm"
      :rules="rules"
      @finish="submit"
    >
      <a-form-item name="paymentPurpose" :label="$t('pages_merchant_012')">
        <div class="explain">{{ $t('pages_merchant_081') }}</div>
        <div class="header-title">
          {{ $t('pages_merchant_084') }}
          <span style="font-size: 12px">{{ $t('pages_merchant_063') }}</span>
        </div>
        <a-checkbox-group
          v-model:value="modelForm.paymentPurpose"
          style="width: 100%"
          :disabled="modelForm.sourceWhereaboutsDisabled"
        >
          <a-checkbox
            v-for="item in sourceWheres"
            :key="item.value"
            :value="item.value"
            :class="modelForm.paymentPurpose.includes(item.value) ? 'checkbox-area selected' : 'checkbox-area'"
          >
            {{ $t(item.key) }}
          </a-checkbox>
        </a-checkbox-group>
        <div v-if="!modelForm.sourceWhereaboutsDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.sourceWhereaboutsMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item style="text-align: right; margin-top: 48px">
        <a-button
          type="primary"
          shape="round"
          ghost
          style="min-width: 138px; margin-right: 24px"
          @click="$emit('next', '3-1')"
        >{{ $t('pages_merchant_040') }}</a-button>
        <a-button
          html-type="submit"
          type="primary"
          shape="round"
          style="min-width: 138px"
        >{{ $t('pages_merchant_020') }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, computed } from 'vue'
import sourceWheres from '@/common/sourceWheres'
export default {
  name: 'SourceWhereabouts',
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    },
    countryCode: {
      type: String,
      default: () => { return '' }
    }
  },
  setup (props, ctx) {
    const step11Ref = ref(null)

    const state = reactive({
      rules: {
        paymentPurpose: [{ required: true }]
      }
    })

    state.modelForm = computed(() => {
      return props.model
    })

    const submit = () => {
      ctx.emit('next', '3-3')
    }

    return {
      step11Ref,
      submit,
      sourceWheres,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#sourceWhereabouts {
  .material-source-whereabouts-form {
    .explain {
      color: #495060;
    }
    .header-title {
      font-size: 16px;
      font-weight: 500;
      margin-top: 24px;
    }
    .checkbox-area {
      display: flex;
      margin: 12px 0 24px 0;
      border: 1px solid #d9d9d9;
      padding: 12px 24px;
      border-radius: 5px;
      cursor: pointer;
    }
    .checkbox-area:hover, .selected {
      border: 1px solid @main-color;
    }
  }
}

</style>

