<template>
  <div id="personCertificate">
    <a-form
      ref="step8Ref"
      class="ant-round-form material-person-certificate-form"
      layout="vertical"
      :model="modelForm"
      :rules="rules"
      @finish="submit"
    >
      <a-form-item name="customer" :label="$t('pages_merchant_009')">
        <div class="explain">
          {{ customerIdentity.includes('0') ? $t('pages_merchant_025') : $t('pages_merchant_065') }}
          <div style="margin-top: 12px">
            <a-tooltip :title="$t('pages_merchant_067')"><a>{{ $t('pages_merchant_066') }}</a></a-tooltip>
          </div>
          <div style="margin-top: 5px">
            <a-tooltip :title="$t('pages_merchant_069')"><a>{{ $t('pages_merchant_068') }}</a></a-tooltip>
          </div>
        </div>
        <div class="header-title">{{ $t('pages_merchant_070') }}</div>
        <div
          v-for="(item, index) in modelForm.customer"
          :key="index"
          class="certificate-area"
          :class="index !== modelForm.customer.length - 1 ? ' border-split' : ''"
        >
          <a-form-item
            :name="['customer', index, 'country']"
            :label="$t('pages_merchant_071')"
            :rules="{ required: true }"
            style="margin-bottom: 12px"
          >
            <a-select
              v-model:value="item.country"
              show-search
              size="large"
              option-filter-prop="label"
              :placeholder="$t('common_text_005') + $t('pages_merchant_071')"
              :disabled="refused ? !refuseAttrs.includes('customer') : item.collDisable"
              @change="e => countryChange(e, 'customer', index)"
            >
              <a-select-option
                v-for="country in countries"
                :key="country.areaCode"
                :value="country.areaCode"
                :label="language === 'zh' ? country.areaChineseName : country.areaEnglishName"
              >{{ language === 'zh' ? country.areaChineseName : country.areaEnglishName }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :name="['customer', index, 'certificateType']"
            :label="$t('pages_merchant_072')"
            :rules="{ required: true }"
            style="margin-bottom: 12px"
          >
            <a-radio-group v-model:value="item.certificateType" :disabled="refused ? !refuseAttrs.includes('customer') : false" button-style="solid" @change="e => radioChange(e.target.value, 'customer', index)">
              <a-radio-button :disabled="!['CN', 'HK'].includes(item.country)" value="0" class="radio-area">{{ $t('pages_merchant_073') }}</a-radio-button>
              <a-radio-button value="1" class="radio-area">{{ $t('pages_merchant_074') }}</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            v-if="item.certificateType === '0'"
            :name="['customer', index, 'cert1']"
            :label="$t('pages_merchant_077')"
            :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_075') }"
            style="margin-bottom: 12px"
          >
            <upload-auto
              :ref="getUploadRef"
              v-model:file-content="item.cert1"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :name-index="'uploadCFRef' + index"
              :disabled="refused ? !refuseAttrs.includes('customer') : item.collDisable"
              :explain="$t('common_text_007') + $t('pages_merchant_075')"
              :max-count="1"
            />
          </a-form-item>
          <a-form-item
            v-if="item.certificateType === '0'"
            :name="['customer', index, 'cert2']"
            :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_076') }"
          >
            <upload-auto
              :ref="getUploadRef"
              v-model:file-content="item.cert2"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :name-index="'uploadCBRef' + index"
              :disabled="refused ? !refuseAttrs.includes('customer') : item.collDisable"
              :explain="$t('common_text_007') + $t('pages_merchant_076')"
              :max-count="1"
            />
          </a-form-item>
          <a-form-item
            v-if="item.certificateType === '1'"
            :label="$t('pages_merchant_077')"
            :name="['customer', index, 'passport']"
            :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_074') }"
          >
            <upload-auto
              :ref="getUploadRef"
              v-model:file-content="item.passport"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :name-index="'uploadCPRef' + index"
              :disabled="refused ? !refuseAttrs.includes('customer') : item.collDisable"
              :explain="$t('common_text_007') + $t('pages_merchant_074')"
              :max-count="1"
            />
          </a-form-item>
        </div>
        <div v-if="refused && refuseAttrs.includes('customer')" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.customerMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item v-if="countryCode === 'CN'" name="legalPerson">
        <div class="header-title">{{ $t('pages_merchant_054') }}</div>
        <div
          v-for="(item, index) in modelForm.legalPerson"
          :key="index"
          class="certificate-area"
          :class="index !== modelForm.legalPerson.length - 1 ? ' border-split' : ''"
        >
          <a-form-item
            :name="['legalPerson', index, 'country']"
            :label="$t('pages_merchant_071')"
            :rules="{ required: true }"
            style="margin-bottom: 12px"
          >
            <a-select
              v-model:value="item.country"
              show-search
              size="large"
              option-filter-prop="label"
              :placeholder="$t('common_text_005') + $t('pages_merchant_071')"
              :disabled="refused ? !refuseAttrs.includes('legalPerson') : item.collDisable"
              @change="e => countryChange(e, 'legalPerson', index)"
            >
              <a-select-option
                v-for="country in countries"
                :key="country.areaCode"
                :value="country.areaCode"
                :label="language === 'zh' ? country.areaChineseName : country.areaEnglishName"
              >{{ language === 'zh' ? country.areaChineseName : country.areaEnglishName }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :name="['legalPerson', index, 'certificateType']"
            :label="$t('pages_merchant_072')"
            :rules="{ required: true }"
            style="margin-bottom: 12px"
          >
            <a-radio-group v-model:value="item.certificateType" :disabled="refused ? !refuseAttrs.includes('legalPerson') : false" button-style="solid" @change="e => radioChange(e.target.value, 'legalPerson', index)">
              <a-radio-button :disabled="!['CN', 'HK'].includes(item.country)" value="0" class="radio-area">{{ $t('pages_merchant_073') }}</a-radio-button>
              <a-radio-button value="1" class="radio-area">{{ $t('pages_merchant_074') }}</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            v-if="item.certificateType === '0'"
            :name="['legalPerson', index, 'cert1']"
            :label="$t('pages_merchant_077')"
            :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_075') }"
            style="margin-bottom: 12px"
          >
            <upload-auto
              :ref="getUploadRef"
              v-model:file-content="item.cert1"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :name-index="'uploadLFRef' + index"
              :disabled="refused ? !refuseAttrs.includes('legalPerson') : item.collDisable"
              :explain="$t('common_text_007') + $t('pages_merchant_075')"
              :max-count="1"
            />
          </a-form-item>
          <a-form-item
            v-if="item.certificateType === '0'"
            :name="['legalPerson', index, 'cert2']"
            :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_076') }"
          >
            <upload-auto
              :ref="getUploadRef"
              v-model:file-content="item.cert2"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :name-index="'uploadLBRef' + index"
              :disabled="refused ? !refuseAttrs.includes('legalPerson') : item.collDisable"
              :explain="$t('common_text_007') + $t('pages_merchant_076')"
              :max-count="1"
            />
          </a-form-item>
          <a-form-item
            v-if="item.certificateType === '1'"
            :label="$t('pages_merchant_077')"
            :name="['legalPerson', index, 'passport']"
            :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_074') }"
          >
            <upload-auto
              :ref="getUploadRef"
              v-model:file-content="item.passport"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :name-index="'uploadLPRef' + index"
              :disabled="refused ? !refuseAttrs.includes('legalPerson') : item.collDisable"
              :explain="$t('common_text_007') + $t('pages_merchant_074')"
              :max-count="1"
            />
          </a-form-item>
          <div v-if="(refused && refuseAttrs.includes('legalPerson')) || (!refused && !item.collDisable)" class="edit-area">
            <plus-circle-filled v-if="index === 0" class="ic-add" @click="handleEdit('legalPerson')" />
            <minus-circle-filled v-else class="ic-delete" @click="handleEdit('legalPerson', index)" />
          </div>
        </div>
        <div v-if="refused && refuseAttrs.includes('legalPerson')" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.legalPersonMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item v-else name="directorPerson">
        <div class="header-title">{{ $t('pages_merchant_056') }}</div>
        <div
          v-for="(item, index) in modelForm.directorPerson"
          :key="index"
          class="certificate-area"
          :class="index !== modelForm.directorPerson.length - 1 ? ' border-split' : ''"
        >
          <a-form-item
            :name="['directorPerson', index, 'country']"
            :label="$t('pages_merchant_071')"
            :rules="{ required: true }"
            style="margin-bottom: 12px"
          >
            <a-select
              v-model:value="item.country"
              show-search
              size="large"
              option-filter-prop="label"
              :placeholder="$t('common_text_005') + $t('pages_merchant_071')"
              :disabled="refused ? !refuseAttrs.includes('director') : item.collDisable"
              @change="e => countryChange(e, 'directorPerson', index)"
            >
              <a-select-option
                v-for="country in countries"
                :key="country.areaCode"
                :value="country.areaCode"
                :label="language === 'zh' ? country.areaChineseName : country.areaEnglishName"
              >{{ language === 'zh' ? country.areaChineseName : country.areaEnglishName }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :name="['directorPerson', index, 'certificateType']"
            :label="$t('pages_merchant_072')"
            :rules="{ required: true }"
            style="margin-bottom: 12px"
          >
            <a-radio-group v-model:value="item.certificateType" :disabled="refused ? !refuseAttrs.includes('director') : false" button-style="solid" @change="e => radioChange(e.target.value, 'directorPerson', index)">
              <a-radio-button :disabled="!['CN', 'HK'].includes(item.country)" value="0" class="radio-area">{{ $t('pages_merchant_073') }}</a-radio-button>
              <a-radio-button value="1" class="radio-area">{{ $t('pages_merchant_074') }}</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            v-if="item.certificateType === '0'"
            :name="['directorPerson', index, 'cert1']"
            :label="$t('pages_merchant_077')"
            :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_075') }"
            style="margin-bottom: 12px"
          >
            <upload-auto
              :ref="getUploadRef"
              v-model:file-content="item.cert1"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :name-index="'uploadDFRef' + index"
              :disabled="refused ? !refuseAttrs.includes('director') : item.collDisable"
              :explain="$t('common_text_007') + $t('pages_merchant_075')"
              :max-count="1"
            />
          </a-form-item>
          <a-form-item
            v-if="item.certificateType === '0'"
            :name="['directorPerson', index, 'cert2']"
            :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_076') }"
          >
            <upload-auto
              :ref="getUploadRef"
              v-model:file-content="item.cert2"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :name-index="'uploadDBRef' + index"
              :disabled="refused ? !refuseAttrs.includes('director') : item.collDisable"
              :explain="$t('common_text_007') + $t('pages_merchant_076')"
              :max-count="1"
            />
          </a-form-item>
          <a-form-item
            v-if="item.certificateType === '1'"
            :label="$t('pages_merchant_077')"
            :name="['directorPerson', index, 'passport']"
            :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_074') }"
          >
            <upload-auto
              :ref="getUploadRef"
              v-model:file-content="item.passport"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :name-index="'uploadDPRef' + index"
              :disabled="refused ? !refuseAttrs.includes('director') : item.collDisable"
              :explain="$t('common_text_007') + $t('pages_merchant_074')"
              :max-count="1"
            />
          </a-form-item>
          <div v-if="(refused && refuseAttrs.includes('director')) || (!refused && !item.collDisable)" class="edit-area">
            <plus-circle-filled v-if="index === 0" class="ic-add" @click="handleEdit('directorPerson')" />
            <minus-circle-filled v-else class="ic-delete" @click="handleEdit('directorPerson', index)" />
          </div>
        </div>
        <div v-if="refused && refuseAttrs.includes('director')" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.directorPersonMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item name="shareholder">
        <div class="header-title">{{ $t('pages_merchant_078') }}</div>
        <div
          v-for="(item, index) in modelForm.shareholder"
          :key="index"
          class="certificate-area"
          :class="index !== modelForm.shareholder.length - 1 ? ' border-split' : ''"
        >
          <a-form-item
            :name="['shareholder', index, 'country']"
            :label="$t('pages_merchant_071')"
            :rules="{ required: true }"
            style="margin-bottom: 12px"
          >
            <a-select
              v-model:value="item.country"
              show-search
              size="large"
              option-filter-prop="label"
              :placeholder="$t('common_text_005') + $t('pages_merchant_071')"
              :disabled="refused ? !refuseAttrs.includes('shareholder') : item.collDisable"
              @change="e => countryChange(e, 'shareholder', index)"
            >
              <a-select-option
                v-for="country in countries"
                :key="country.areaCode"
                :value="country.areaCode"
                :label="language === 'zh' ? country.areaChineseName : country.areaEnglishName"
              >{{ language === 'zh' ? country.areaChineseName : country.areaEnglishName }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :name="['shareholder', index, 'certificateType']"
            :label="$t('pages_merchant_072')"
            :rules="{ required: true }"
            style="margin-bottom: 12px"
          >
            <a-radio-group v-model:value="item.certificateType" :disabled="refused ? !refuseAttrs.includes('shareholder') : false" button-style="solid" @change="e => radioChange(e.target.value, 'shareholder', index)">
              <a-radio-button :disabled="!['CN', 'HK'].includes(item.country)" value="0" class="radio-area">{{ $t('pages_merchant_073') }}</a-radio-button>
              <a-radio-button value="1" class="radio-area">{{ $t('pages_merchant_074') }}</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            v-if="item.certificateType === '0'"
            :name="['shareholder', index, 'cert1']"
            :label="$t('pages_merchant_077')"
            :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_075') }"
            style="margin-bottom: 12px"
          >
            <upload-auto
              :ref="getUploadRef"
              v-model:file-content="item.cert1"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :name-index="'uploadSFRef' + index"
              :disabled="refused ? !refuseAttrs.includes('shareholder') : item.collDisable"
              :explain="$t('common_text_007') + $t('pages_merchant_075')"
              :max-count="1"
            />
          </a-form-item>
          <a-form-item
            v-if="item.certificateType === '0'"
            :name="['shareholder', index, 'cert2']"
            :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_076') }"
          >
            <upload-auto
              :ref="getUploadRef"
              v-model:file-content="item.cert2"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :name-index="'uploadSBRef' + index"
              :disabled="refused ? !refuseAttrs.includes('shareholder') : item.collDisable"
              :explain="$t('common_text_007') + $t('pages_merchant_076')"
              :max-count="1"
            />
          </a-form-item>
          <a-form-item
            v-if="item.certificateType === '1'"
            :label="$t('pages_merchant_077')"
            :name="['shareholder', index, 'passport']"
            :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_074') }"
          >
            <upload-auto
              :ref="getUploadRef"
              v-model:file-content="item.passport"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :name-index="'uploadSPRef' + index"
              :disabled="refused ? !refuseAttrs.includes('shareholder') : item.collDisable"
              :explain="$t('common_text_007') + $t('pages_merchant_074')"
              :max-count="1"
            />
          </a-form-item>
          <div v-if="(refused && refuseAttrs.includes('shareholder')) || (!refused && !item.collDisable)" class="edit-area">
            <plus-circle-filled v-if="index === 0" class="ic-add" @click="handleEdit('shareholder')" />
            <minus-circle-filled v-else class="ic-delete" @click="handleEdit('shareholder', index)" />
          </div>
        </div>
        <div v-if="refused && refuseAttrs.includes('shareholder')" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.shareholderMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item style="text-align: right; margin-top: 48px">
        <a-button
          type="primary"
          shape="round"
          ghost
          style="min-width: 138px; margin-right: 24px"
          @click="before()"
        >{{ $t('pages_merchant_040') }}</a-button>
        <a-button
          html-type="submit"
          type="primary"
          shape="round"
          style="min-width: 138px"
        >{{ $t('pages_merchant_020') }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, computed, nextTick, watch, onUnmounted } from 'vue'
import UploadAuto from '@/components/upload-auto'
export default {
  name: 'PersonCertificate',
  components: {
    'upload-auto': UploadAuto
  },
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    },
    countryCode: {
      type: String,
      default: () => { return '' }
    },
    customerIdentity: {
      type: Array,
      default: () => { return [] }
    },
    countries: {
      type: Array,
      default: () => { return [] }
    },
    refuseAttrs: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup (props, ctx) {
    const step8Ref = ref(null)

    const state = reactive({
      language: localStorage.getItem('locale-language') || 'zh',
      rules: {
        customer: [{ required: true }]
      }
    })

    state.modelForm = computed(() => {
      return props.model
    })

    onUnmounted(() => {
      clear()
    })

    watch(
      () => state.modelForm.customer,
      values => {
        const { certificateType, cert1, cert2, passport } = values[0]
        if (props.refused) return
        if ((certificateType === '0' && cert1.length !== 0 && cert2.length !== 0) || (certificateType === '1' && passport.length !== 0)) {
          state.modelForm.legalPerson[0].collDisable = false
          state.modelForm.directorPerson[0].collDisable = false
          state.modelForm.shareholder[0].collDisable = false

          if (props.customerIdentity.includes('0')) {
            state.modelForm.legalPerson[0] = Object.assign({}, values[0], {})
            nextTick(() => {
              if (certificateType === '0') {
                if (state.uploadLFRef0) state.uploadLFRef0.fileListRefresh(cert1)
                if (state.uploadLBRef0) state.uploadLBRef0.fileListRefresh(cert2)
              }
              if (certificateType === '1') {
                if (state.uploadLPRef0) state.uploadLPRef0.fileListRefresh(passport)
              }
            })
          }
          if (props.customerIdentity.includes('1')) {
            state.modelForm.directorPerson[0] = Object.assign({}, values[0], {})
            nextTick(() => {
              if (certificateType === '0') {
                if (state.uploadDFRef0) state.uploadDFRef0.fileListRefresh(cert1)
                if (state.uploadDBRef0) state.uploadDBRef0.fileListRefresh(cert2)
              }
              if (certificateType === '1') {
                if (state.uploadDPRef0) state.uploadDPRef0.fileListRefresh(passport)
              }
            })
          }
          if (props.customerIdentity.includes('2')) {
            state.modelForm.shareholder[0] = Object.assign({}, values[0], {})
            nextTick(() => {
              if (certificateType === '0') {
                if (state.uploadSFRef0) state.uploadSFRef0.fileListRefresh(cert1)
                if (state.uploadSBRef0) state.uploadSBRef0.fileListRefresh(cert2)
              }
              if (certificateType === '1') {
                if (state.uploadSPRef0) state.uploadSPRef0.fileListRefresh(passport)
              }
            })
          }
        }
      },
      { deep: true }
    )

    const getUploadRef = (el) => {
      if (el) state[el.nameIndex] = el
    }

    const radioChange = (e, attr, index) => {
      nextTick(() => {
        loadPath(e, attr, index)
        if (attr === 'customer' && !props.refused) {
          if (props.customerIdentity.includes('0')) loadPath(e, 'legalPerson', index)
          if (props.customerIdentity.includes('1')) loadPath(e, 'directorPerson', index)
          if (props.customerIdentity.includes('2')) loadPath(e, 'shareholder', index)
        }
      })
    }

    const countryChange = (e, attr, index) => {
      if (['HK', 'CN'].includes(e)) {
        state.modelForm[attr][index].certificateType = ''
      } else {
        state.modelForm[attr][index].certificateType = '1'
      }
    }

    const handleEdit = (attr, currentIndex) => {
      if (currentIndex) {
        state.modelForm[attr] = state.modelForm[attr].filter((item, index) => index !== currentIndex)
      } else {
        state.modelForm[attr].push({
          collDisable: false,
          certificateType: '',
          country: null,
          cert1: [],
          cert2: [],
          passport: []
        })
      }
    }

    const before = () => {
      ctx.emit('next', '2-4')
    }

    const submit = () => {
      ctx.emit('next', '2-6')
    }

    const clear = () => {
      const attrs = ['customer', 'legalPerson', 'directorPerson', 'shareholder']
      for (const attr of attrs) {
        for (const item of state.modelForm[attr]) {
          if (item.certificateType === '0') {
            item.passport = []
          }
          if (item.certificateType === '1') {
            item.cert1 = []
            item.cert2 = []
          }
        }
      }
    }

    nextTick(() => {
      const attrs = ['customer', 'legalPerson', 'directorPerson', 'shareholder']
      for (const item of attrs) {
        for (let index = 0; index < state.modelForm[item].length; index++) {
          loadPath(state.modelForm[item][index].certificateType, item, index)
        }
      }
    })

    const loadPath = (type, attr, index) => {
      const ref = attr === 'customer' ? 'uploadC' : attr === 'legalPerson' ? 'uploadL' : attr === 'directorPerson' ? 'uploadD' : 'uploadS'

      if (type === '0' && state[`${ref}FRef${index}`] && state.modelForm[attr][index].cert1.length !== 0) {
        state[`${ref}FRef${index}`].fileListRefresh(state.modelForm[attr][index].cert1)
      }
      if (type === '0' && state[`${ref}BRef${index}`] && state.modelForm[attr][index].cert2.length !== 0) {
        state[`${ref}BRef${index}`].fileListRefresh(state.modelForm[attr][index].cert2)
      }
      if (type === '1' && state[`${ref}PRef${index}`] && state.modelForm[attr][index].passport.length !== 0) {
        state[`${ref}PRef${index}`].fileListRefresh(state.modelForm[attr][index].passport)
      }
    }

    return {
      step8Ref,
      before,
      submit,
      handleEdit,
      radioChange,
      countryChange,
      getUploadRef,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#personCertificate {
  .header-img {
    margin-bottom: 36px;
    img {
      width: 200px;
    }
  }
  .material-person-certificate-form {
    .explain {
      color: #495060;
    }
    .header-title {
      font-size: 16px;
      font-weight: 500;
      margin-top: 24px;
    }
    .certificate-area {
      margin-top: 24px;
      padding: 0 12px 18px 12px;
      .radio-area {
        height: auto;
        min-width: 180px;
        text-align: center;
      }
      .edit-area {
        text-align: right;
        padding-right: 12px;
        margin-top: -6px;
        .ic-delete, .ic-add  {
          color: @main-color;
          cursor: pointer;
          font-size: 20px;
        }
      }
    }
    .border-split {
      border-bottom: 2px dashed #d9d9d9;
    }
    .certificate-area:deep(.ant-form-item-label) {
      label {
        font-size: 14px !important;
      }
    }
  }
}

</style>

