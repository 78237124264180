<template>
  <div id="riskSupply">
    <a-alert type="error" :message="$t('pages_merchant_093')" banner />
    <a-form
      ref="step13Ref"
      class="ant-round-form material-risk-supply-form"
      layout="vertical"
      :model="modelForm"
      :rules="rules"
      @finish="submit"
    >

      <a-form-item name="financialStatementsPath" :label="$t('pages_merchant_094')">
        <upload-auto
          ref="uploadFRef"
          v-model:file-content="modelForm.financialStatementsPath"
          accept=".jpg,.jpeg,.png,.pdf,.bmp"
          type="INVITE"
          list-type="picture"
          back-type="array"
          :disabled="modelForm.statementsPathDisabled"
          :explain="$t('pages_merchant_032')"
          :max-count="5"
        />
        <div v-if="!modelForm.statementsPathDisabled && refused && modelForm.statementsPathMessage" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.statementsPathMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item name="questionnairePath" :label="$t('pages_merchant_095')">
        <div class="explain" style="margin-bottom: 24px">
          <a @click="downOwnerPath('questionnaire')">{{ $t('pages_merchant_096') }}</a>
        </div>
        <upload-auto
          ref="uploadQRef"
          v-model:file-content="modelForm.questionnairePath"
          accept=".jpg,.jpeg,.png,.pdf,.bmp"
          type="INVITE"
          list-type="picture"
          back-type="array"
          :disabled="modelForm.questionnairePathDisabled"
          :explain="$t('pages_merchant_032')"
          :max-count="5"
        />
        <div v-if="!modelForm.questionnairePathDisabled && refused && modelForm.questionnairePathMessage" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.questionnairePathMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item name="customer" :label="$t('pages_merchant_097')">
        <span class="explain">{{ $t('pages_merchant_098') }}</span>
        <div
          v-for="(item, index) in modelForm.customer"
          :key="index"
          class="trade-country-area"
        >
          <a-form-item
            :name="['customer', index, 'country']"
            :rules="{ required: true, message: $t('pages_merchant_101') }"
            style="margin-bottom: 12px"
          >
            <a-select
              v-model:value="item.country"
              show-search
              size="large"
              option-filter-prop="label"
              :placeholder="$t('pages_merchant_101')"
              :disabled="modelForm.customerDisabled"
            >
              <a-select-option
                v-for="country in countries"
                :key="country.areaCode"
                :value="country.areaCode"
                :label="language === 'zh' ? country.areaChineseName : country.areaEnglishName"
              >{{ language === 'zh' ? country.areaChineseName : country.areaEnglishName }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :name="['customer', index, 'name']"
            :rules="{ required: true, message: $t('pages_merchant_102') }"
          >
            <a-input
              v-model:value="item.name"
              size="large"
              allow-clear
              :placeholder="$t('pages_merchant_102')"
              :disabled="modelForm.customerDisabled"
            />
          </a-form-item>
          <div v-if="!modelForm.customerDisabled" class="edit-area">
            <plus-circle-filled v-if="index === 0" :style="{cursor: modelForm.customer.length < 3 ? '' : 'not-allowed'}" class="ic-add" @click="handleEdit('customer', 0)" />
            <minus-circle-filled v-else class="ic-delete" @click="handleEdit('customer', index)" />
          </div>
        </div>
        <div v-if="!modelForm.customerDisabled && refused && modelForm.customerMessage" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.customerMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item name="supplier" :label="$t('pages_merchant_099')">
        <span class="explain">{{ $t('pages_merchant_100') }}</span>
        <div
          v-for="(item, index) in modelForm.supplier"
          :key="index"
          class="trade-country-area"
        >
          <a-form-item
            :name="['supplier', index, 'country']"
            :rules="{ required: true, message: $t('pages_merchant_103') }"
            style="margin-bottom: 12px"
          >
            <a-select
              v-model:value="item.country"
              show-search
              size="large"
              option-filter-prop="label"
              :placeholder="$t('pages_merchant_103')"
              :disabled="modelForm.supplierDisabled"
            >
              <a-select-option
                v-for="country in countries"
                :key="country.areaCode"
                :value="country.areaCode"
                :label="language === 'zh' ? country.areaChineseName : country.areaEnglishName"
              >{{ language === 'zh' ? country.areaChineseName : country.areaEnglishName }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :name="['supplier', index, 'name']"
            :rules="{ required: true, message: $t('pages_merchant_104') }"
          >
            <a-input
              v-model:value="item.name"
              size="large"
              allow-clear
              :placeholder="$t('pages_merchant_104')"
              :disabled="modelForm.supplierDisabled"
            />
          </a-form-item>
          <div v-if="!modelForm.supplierDisabled" class="edit-area">
            <plus-circle-filled v-if="index === 0" :style="{cursor: modelForm.supplier.length < 3 ? '' : 'not-allowed'}" class="ic-add" @click="handleEdit('supplier', 0)" />
            <minus-circle-filled v-else class="ic-delete" @click="handleEdit('supplier', index)" />
          </div>
        </div>
        <div v-if="!modelForm.supplierDisabled && refused && modelForm.supplierMessage" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.supplierMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item style="text-align: right; margin-top: 48px">
        <a-button
          type="primary"
          shape="round"
          ghost
          style="min-width: 138px; margin-right: 24px"
          @click="$emit('next', '3-3')"
        >{{ $t('pages_merchant_040') }}</a-button>
        <a-button
          html-type="submit"
          type="primary"
          shape="round"
          style="min-width: 138px"
        >{{ $t('pages_merchant_020') }}</a-button>
      </a-form-item>
    </a-form>

  </div>
</template>
<script>
import { ref, reactive, toRefs, computed, nextTick } from 'vue'
import UploadAuto from '@/components/upload-auto'
import { _user } from '@/api'
import i18n from '@/locale'
export default {
  name: 'RiskSupply',
  components: {
    'upload-auto': UploadAuto
  },
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    },
    countries: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup (props, ctx) {
    const step13Ref = ref(null)
    const uploadFRef = ref(null)
    const uploadQRef = ref(null)

    const state = reactive({
      language: localStorage.getItem('locale-language') || 'zh',
      rules: {
        customer: [{ required: true }],
        supplier: [{ required: true }],
        questionnairePath: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_merchant_095') }]
      }
    })

    state.modelForm = computed(() => {
      return props.model
    })

    const downOwnerPath = async (name) => {
      const res = await _user.exportMerchantTemplate({ name })
      if (res.data.succ) window.open(res.data.data)
    }

    const handleEdit = (attr, currentIndex) => {
      if (currentIndex) {
        state.modelForm[attr] = state.modelForm[attr].filter((item, index) => index !== currentIndex)
      } else {
        if (state.modelForm[attr].length >= 3) return
        state.modelForm[attr].push({ country: null, name: '' })
      }
    }

    const submit = () => {
      ctx.emit('next', '3-5')
    }

    const loadPath = () => {
      nextTick(() => {
        const { financialStatementsPath, questionnairePath } = state.modelForm
        if (financialStatementsPath.length !== 0 && uploadFRef.value) uploadFRef.value.fileListRefresh(financialStatementsPath)
        if (questionnairePath.length !== 0 && uploadQRef.value) uploadQRef.value.fileListRefresh(questionnairePath)
      })
    }

    loadPath()

    return {
      step13Ref,
      uploadFRef,
      uploadQRef,
      submit,
      loadPath,
      handleEdit,
      downOwnerPath,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#riskSupply {
  .header-img {
    margin-bottom: 36px;
    img {
      width: 200px;
    }
  }
  .material-risk-supply-form {
    margin-top: 24px;
    .explain {
      color: #495060;
    }
    .trade-country-area {
      margin-top: 24px;
      .edit-area {
        text-align: right;
        padding-right: 12px;
        margin-top: -6px;
        .ic-delete, .ic-add  {
          color: @main-color;
          cursor: pointer;
          font-size: 20px;
        }
      }
    }
  }
}

</style>

