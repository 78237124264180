<template>
  <div id="companySons">
    <div class="header-img">
      <img src="~@/assets/images/guide-company.png">
    </div>
    <a-form
      ref="step3Ref"
      class="ant-round-form material-company-sons-form"
      layout="vertical"
      :model="modelForm"
      :rules="rules"
      @finish="submit"
    >
      <a-form-item name="branchRadio" :label="$t('pages_merchant_041')">
        <a-radio-group v-model:value="modelForm.branchRadio" :disabled="modelForm.branchRadioDisabled">
          <a-radio value="1">{{ $t('pages_merchant_022') }}</a-radio>
          <a-radio value="0">{{ $t('pages_merchant_023') }}</a-radio>
        </a-radio-group>
        <div v-if="!modelForm.branchRadioDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.branchRadioMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item v-if="modelForm.branchRadio === '1'" name="tradeCountry" :label="$t('pages_merchant_042')">
        <span class="explain">{{ $t('pages_merchant_043') }}</span>
        <div
          v-for="(item, index) in modelForm.tradeCountry"
          :key="item.id"
          class="trade-country-area"
        >
          <a-form-item
            :name="['tradeCountry', index, 'country']"
            :rules="{ required: true, message: $t('pages_merchant_044') }"
            style="margin-bottom: 12px"
          >
            <a-select
              v-model:value="item.country"
              show-search
              size="large"
              option-filter-prop="label"
              :placeholder="$t('pages_merchant_044')"
              :disabled="modelForm.tradeCountryDisabled"
            >
              <a-select-option
                v-for="country in countries"
                :key="country.areaCode"
                :value="country.areaCode"
                :label="language === 'zh' ? country.areaChineseName : country.areaEnglishName"
              >{{ language === 'zh' ? country.areaChineseName : country.areaEnglishName }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :name="['tradeCountry', index, 'name']"
            :rules="{ required: true, message: $t('pages_merchant_045') }"
          >
            <a-input
              v-model:value="item.name"
              size="large"
              allow-clear
              :placeholder="$t('pages_merchant_045')"
              :disabled="modelForm.tradeCountryDisabled"
            />
          </a-form-item>
          <div v-if="!modelForm.tradeCountryDisabled" class="edit-area">
            <plus-circle-filled v-if="index === 0" class="ic-add" @click="handleEdit(0)" />
            <minus-circle-filled v-else class="ic-delete" @click="handleEdit(index)" />
          </div>
        </div>
        <div v-if="!modelForm.tradeCountryDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.tradeCountryMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item style="text-align: right; margin-top: 48px">
        <a-button
          type="primary"
          shape="round"
          ghost
          style="min-width: 138px; margin-right: 24px"
          @click="$emit('next', '1-2')"
        >{{ $t('pages_merchant_040') }}</a-button>
        <a-button
          html-type="submit"
          type="primary"
          shape="round"
          style="min-width: 138px"
        >{{ $t('pages_merchant_020') }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, computed } from 'vue'
export default {
  name: 'CompanySons',
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    },
    countries: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup (props, ctx) {
    const step3Ref = ref(null)

    const state = reactive({
      language: localStorage.getItem('locale-language') || 'zh',
      rules: {
        branchRadio: [{ required: true }],
        tradeCountry: [{ required: true }]
      }
    })

    state.modelForm = computed(() => {
      return props.model
    })

    const handleEdit = (currentIndex) => {
      if (currentIndex) state.modelForm.tradeCountry = state.modelForm.tradeCountry.filter((item, index) => index !== currentIndex)
      else state.modelForm.tradeCountry.push({ country: null, name: '' })
    }

    const submit = () => {
      ctx.emit('next', '2-1')
    }

    return {
      step3Ref,
      submit,
      handleEdit,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#companySons {
  .header-img {
    margin-bottom: 36px;
    img {
      width: 200px;
    }
  }
  .material-company-sons-form {
    .explain {
      color: #495060;
    }
    .trade-country-area {
      margin-top: 24px;
      .edit-area {
        text-align: right;
        padding-right: 12px;
        margin-top: -6px;
        .ic-delete, .ic-add  {
          color: @main-color;
          cursor: pointer;
          font-size: 20px;
        }
      }
    }
  }
}

</style>

