<template>
  <div id="authorIdentity">
    <div class="header-img">
      <img src="~@/assets/images/guide-identity.png">
    </div>
    <a-form
      ref="step7Ref"
      class="ant-round-form material-author-identity-form"
      layout="vertical"
      :model="modelForm"
      :rules="rules"
      @finish="submit"
    >
      <a-form-item name="customerIdentity">
        <template #label>
          <span>{{ $t('pages_merchant_062') }}</span>
          <span style="font-size: 12px">{{ $t('pages_merchant_063') }}</span>
        </template>
        <a-checkbox-group
          v-model:value="modelForm.customerIdentity"
          :disabled="modelForm.customerIdentityDisabled"
          @change="checkboxChange"
        >
          <a-checkbox
            v-if="countryCode === 'CN'"
            value="0"
            :class="modelForm.customerIdentity.includes('0') ? 'checkbox-area selected' : 'checkbox-area'"
          >
            {{ $t('pages_merchant_054') }}
            <div style="margin: 5px 0 0 -24px">{{ $t('pages_merchant_055') }}</div>
          </a-checkbox>
          <a-checkbox
            v-else
            value="1"
            :class="modelForm.customerIdentity.includes('1') ? 'checkbox-area selected' : 'checkbox-area'"
          >
            {{ $t('pages_merchant_056') }}
            <div style="margin: 5px 0 0 -24px">{{ $t('pages_merchant_057') }}</div>
          </a-checkbox>
          <a-checkbox
            value="2"
            :class="modelForm.customerIdentity.includes('2') ? 'checkbox-area selected' : 'checkbox-area'"
          >
            {{ $t('pages_merchant_058') }}
            <div style="margin: 5px 0 0 -24px">{{ $t('pages_merchant_059') }}</div>
          </a-checkbox>
          <a-checkbox
            value="3"
            :class="modelForm.customerIdentity.includes('3') ? 'checkbox-area selected' : 'checkbox-area'"
          >
            {{ $t('pages_merchant_060') }}
            <div style="margin: 5px 0 0 -24px">{{ $t('pages_merchant_061') }}</div>
          </a-checkbox>
        </a-checkbox-group>
        <div v-if="!modelForm.customerIdentityDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.customerIdentityMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item style="text-align: right; margin-top: 48px">
        <a-button
          type="primary"
          shape="round"
          ghost
          style="min-width: 138px; margin-right: 24px"
          @click="$emit('next', ['CN', 'HK'].includes(countryCode) ? '2-1' : '2-3')"
        >{{ $t('pages_merchant_040') }}</a-button>
        <a-button
          html-type="submit"
          type="primary"
          shape="round"
          style="min-width: 138px"
        >{{ $t('pages_merchant_020') }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, computed } from 'vue'
export default {
  name: 'AuthorIdentity',
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    },
    countryCode: {
      type: String,
      default: () => { return '' }
    }
  },
  setup (props, ctx) {
    const step7Ref = ref(null)

    const state = reactive({
      rules: {
        customerIdentity: [{ required: true }]
      }
    })

    state.modelForm = computed(() => {
      return props.model
    })

    const checkboxChange = (e) => {
      if (e[e.length - 1] === '3') state.modelForm.customerIdentity = ['3']
      else state.modelForm.customerIdentity = state.modelForm.customerIdentity.filter(item => item !== '3')
      ctx.emit('edit')
    }

    const submit = () => {
      ctx.emit('next', '2-5')
    }

    return {
      step7Ref,
      submit,
      checkboxChange,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#authorIdentity {
  .header-img {
    margin-bottom: 36px;
    img {
      width: 200px;
    }
  }
  .material-author-identity-form {
    .checkbox-area {
      display: flex;
      margin: 12px 0 24px 0;
      border: 1px solid #d9d9d9;
      padding: 12px 24px;
      border-radius: 5px;
      cursor: pointer;
    }
    .checkbox-area:hover, .selected {
      border: 1px solid @main-color;
    }
  }
}

</style>

