<template>
  <div id="companyParents">
    <div class="header-img">
      <img src="~@/assets/images/guide-company.png">
    </div>
    <a-form
      ref="step2Ref"
      class="ant-round-form material-company-parents-form"
      layout="vertical"
      :model="modelForm"
      :rules="rules"
      @finish="submit"
    >
      <a-form-item name="radioShow" :label="$t('pages_merchant_021')">
        <a-radio-group v-model:value="modelForm.radioShow" :disabled="modelForm.radioShowDisabled" @change="loadPath">
          <a-radio value="1">{{ $t('pages_merchant_022') }}</a-radio>
          <a-radio value="0">{{ $t('pages_merchant_023') }}</a-radio>
        </a-radio-group>
        <div v-if="!modelForm.radioShowDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.radioShowMessage }}</span>
        </div>
      </a-form-item>

      <div v-if="modelForm.radioShow === '1'">
        <a-form-item name="tradeCountry" :label="$t('pages_merchant_024')">
          <span class="explain">{{ $t('pages_merchant_025') }}</span>
          <div
            v-for="(item, index) in modelForm.tradeCountry"
            :key="item.id"
            class="trade-country-area"
          >
            <a-form-item
              :name="['tradeCountry', index, 'country']"
              :rules="{ required: true, message: $t('pages_merchant_026') }"
              style="margin-bottom: 12px"
            >
              <a-select
                v-model:value="item.country"
                show-search
                size="large"
                option-filter-prop="label"
                :placeholder="$t('pages_merchant_026')"
                :disabled="modelForm.tradeCountryDisabled"
              >
                <a-select-option
                  v-for="country in countries"
                  :key="country.areaCode"
                  :value="country.areaCode"
                  :label="language === 'zh' ? country.areaChineseName : country.areaEnglishName"
                >{{ language === 'zh' ? country.areaChineseName : country.areaEnglishName }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              :name="['tradeCountry', index, 'name']"
              :rules="{ required: true, message: $t('pages_merchant_027') }"
            >
              <a-input
                v-model:value="item.name"
                size="large"
                allow-clear
                :placeholder="$t('pages_merchant_027')"
                :disabled="modelForm.tradeCountryDisabled"
              />
            </a-form-item>
            <div v-if="!modelForm.tradeCountryDisabled" class="edit-area">
              <plus-circle-filled v-if="index === 0" class="ic-add" @click="handleEdit(0)" />
              <minus-circle-filled v-else class="ic-delete" @click="handleEdit(index)" />
            </div>
          </div>
          <div v-if="!modelForm.tradeCountryDisabled && refused" class="error-area">
            <exclamation-circle-outlined class="ic-error" />
            <span>{{ modelForm.tradeCountryMessage }}</span>
          </div>
        </a-form-item>

        <a-form-item name="ownerShipPath" :label="$t('pages_merchant_028')">
          <div class="explain" style="margin-bottom: 24px">
            <div>{{ $t('pages_merchant_029') }}</div>
            <div>{{ $t('pages_merchant_030') }}</div>
            <div>{{ $t('pages_merchant_031') }}</div>
            <a
              style="display: inline-block; margin-top: 12px; font-weight: 500"
              @click="downOwnerPath('equity')"
            >{{ $t('pages_merchant_033') }}</a>
          </div>
          <upload-auto
            ref="uploadORef"
            v-model:file-content="modelForm.ownerShipPath"
            accept=".jpg,.jpeg,.png,.pdf,.bmp"
            type="INVITE"
            list-type="picture"
            back-type="array"
            :disabled="modelForm.ownerShipPathDisabled"
            :explain="$t('pages_merchant_032')"
            :max-count="5"
          />
          <div v-if="!modelForm.ownerShipPathDisabled && refused" class="error-area">
            <exclamation-circle-outlined class="ic-error" />
            <span>{{ modelForm.ownerShipPathMessage }}</span>
          </div>
        </a-form-item>

        <a-form-item
          name="parentCertificatePath"
          :label="$t('pages_merchant_034')"
          :rules="{ required: isRequire, message: $t('common_text_007') }"
        >
          <div class="explain" style="margin-bottom: 24px">
            <span>{{ $t('pages_merchant_035') }}</span>
            <a :href="require('/src/assets/files/weiJingQunDao.png')" target="_blank">{{ $t('pages_merchant_036') }}</a> /
            <a :href="require('/src/assets/files/kaiMan.png')" target="_blank">{{ $t('pages_merchant_037') }}</a> /
            <a :href="require('/src/assets/files/incumbency.png')" target="_blank">{{ $t('pages_merchant_038') }}</a>
            <span>{{ $t('pages_merchant_039') }}</span>
          </div>
          <upload-auto
            ref="uploadPRef"
            v-model:file-content="modelForm.parentCertificatePath"
            accept=".jpg,.jpeg,.png,.pdf,.bmp"
            type="INVITE"
            list-type="picture"
            back-type="array"
            :disabled="modelForm.certificatePathDisabled"
            :explain="$t('pages_merchant_032')"
            :max-count="5"
          />
          <div v-if="!modelForm.certificatePathDisabled && refused" class="error-area">
            <exclamation-circle-outlined class="ic-error" />
            <span>{{ modelForm.certificatePathMessage }}</span>
          </div>
        </a-form-item>
      </div>

      <a-form-item style="text-align: right; margin-top: 48px">
        <a-button
          type="primary"
          shape="round"
          ghost
          style="min-width: 138px; margin-right: 24px"
          @click="$emit('next', '1-1')"
        >{{ $t('pages_merchant_040') }}</a-button>
        <a-button
          html-type="submit"
          type="primary"
          shape="round"
          style="min-width: 138px"
        >{{ $t('pages_merchant_020') }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, computed, nextTick } from 'vue'
import UploadAuto from '@/components/upload-auto'
import { _user } from '@/api'
import i18n from '@/locale'
export default {
  name: 'CompanyParents',
  components: {
    'upload-auto': UploadAuto
  },
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    },
    countries: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup (props, ctx) {
    const step2Ref = ref(null)
    const uploadORef = ref(null)
    const uploadPRef = ref(null)

    const state = reactive({
      language: localStorage.getItem('locale-language') || 'zh',
      rules: {
        radioShow: [{ required: true }],
        tradeCountry: [{ required: true }],
        ownerShipPath: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_merchant_028') }]
      }
    })

    state.modelForm = computed(() => {
      return props.model
    })

    state.isRequire = computed(() => {
      return state.modelForm.tradeCountry.some(item => item.country !== 'CN')
    })

    const downOwnerPath = async (name) => {
      const res = await _user.exportMerchantTemplate({ name })
      if (res.data.succ) window.open(res.data.data)
    }

    const handleEdit = (currentIndex) => {
      if (currentIndex) state.modelForm.tradeCountry = state.modelForm.tradeCountry.filter((item, index) => index !== currentIndex)
      else state.modelForm.tradeCountry.push({ country: null, name: '' })
    }

    const submit = () => {
      ctx.emit('next', '1-3')
    }

    const loadPath = () => {
      if (state.modelForm.radioShow === '1') {
        nextTick(() => {
          const { ownerShipPath, parentCertificatePath } = state.modelForm
          if (ownerShipPath.length !== 0 && uploadORef.value) uploadORef.value.fileListRefresh(ownerShipPath)
          if (parentCertificatePath.length !== 0 && uploadPRef.value) uploadPRef.value.fileListRefresh(parentCertificatePath)
        })
      }
    }

    loadPath()

    return {
      step2Ref,
      uploadORef,
      uploadPRef,
      submit,
      loadPath,
      handleEdit,
      downOwnerPath,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#companyParents {
  .header-img {
    margin-bottom: 36px;
    img {
      width: 200px;
    }
  }
  .material-company-parents-form {
    .explain {
      color: #495060;
    }
    .trade-country-area {
      margin-top: 24px;
      .edit-area {
        text-align: right;
        padding-right: 12px;
        margin-top: -6px;
        .ic-delete, .ic-add  {
          color: @main-color;
          cursor: pointer;
          font-size: 20px;
        }
      }
    }
  }
}

</style>

