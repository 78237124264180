<template>
  <div id="annualReportInc">
    <a-form
      ref="step6Ref"
      class="ant-round-form material-annual-report-inc-form"
      layout="vertical"
      :model="modelForm"
      :rules="rules"
      @finish="submit"
    >

      <a-form-item name="nar1Path1" :label="$t('pages_merchant_050')">
        <div class="explain" style="margin-bottom: 24px">
          <span>{{ $t('pages_merchant_052') }}</span>
        </div>
        <upload-auto
          ref="uploadRRef"
          v-model:file-content="modelForm.nar1Path1"
          accept=".jpg,.jpeg,.png,.pdf,.bmp"
          type="INVITE"
          list-type="picture"
          back-type="array"
          :disabled="modelForm.nar1Path1Disabled"
          :explain="$t('pages_merchant_032')"
          :max-count="5"
        />
        <div v-if="!modelForm.nar1Path1Disabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.nar1Path1Message }}</span>
        </div>
      </a-form-item>

      <a-form-item name="nnc1Path" :label="$t('pages_merchant_051')">
        <div class="explain" style="margin-bottom: 24px">
          <span>{{ $t('pages_merchant_053') }}</span>
        </div>
        <upload-auto
          ref="uploadCRef"
          v-model:file-content="modelForm.nnc1Path"
          accept=".jpg,.jpeg,.png,.pdf,.bmp"
          type="INVITE"
          list-type="picture"
          back-type="array"
          :disabled="modelForm.nnc1PathDisabled"
          :explain="$t('pages_merchant_032')"
          :max-count="5"
        />
        <div v-if="!modelForm.nnc1PathDisabled && refused" class="error-area">
          <exclamation-circle-outlined class="ic-error" />
          <span>{{ modelForm.nnc1PathMessage }}</span>
        </div>
      </a-form-item>

      <a-form-item style="text-align: right; margin-top: 48px">
        <a-button
          type="primary"
          shape="round"
          ghost
          style="min-width: 138px; margin-right: 24px"
          @click="$emit('next', '2-2')"
        >{{ $t('pages_merchant_040') }}</a-button>
        <a-button
          html-type="submit"
          type="primary"
          shape="round"
          style="min-width: 138px"
        >{{ $t('pages_merchant_020') }}</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { ref, reactive, toRefs, computed, nextTick } from 'vue'
import UploadAuto from '@/components/upload-auto'
import i18n from '@/locale'
export default {
  name: 'AnnualReportInc',
  components: {
    'upload-auto': UploadAuto
  },
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup (props, ctx) {
    const step6Ref = ref(null)
    const uploadRRef = ref(null)
    const uploadCRef = ref(null)

    const state = reactive({
      rules: {
        nnc1Path: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_merchant_053') }]
      }
    })

    state.modelForm = computed(() => {
      return props.model
    })

    const submit = () => {
      ctx.emit('next', '2-4')
    }

    nextTick(() => {
      if (uploadRRef.value && state.modelForm.nar1Path1.length !== 0) uploadRRef.value.fileListRefresh(state.modelForm.nar1Path1)
      if (uploadCRef.value && state.modelForm.nnc1Path.length !== 0) uploadCRef.value.fileListRefresh(state.modelForm.nnc1Path)
    })

    return {
      step6Ref,
      uploadRRef,
      uploadCRef,
      submit,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#annualReportInc {
  .material-annual-report-inc-form {
    .explain {
      color: #495060;
    }
  }
}

</style>

